import Swiper from 'swiper/swiper-bundle.min'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

export default function index(){
  const eventSlidesParams = {
    spaceBetween: 12,
    slidesPerView: 'auto',
    centeredSlides: true,
    loop: true,
    speed: 600,
    grabCursor: true,
    navigation: {
      nextEl: ".event-button-next",
      prevEl: ".event-button-prev",
    },
    breakpoints: {
      768: {
        spaceBetween: 24,
      },
    }
  }

  const servicePicSlideParams = {
    spaceBetween: 8,
    slidesPerView: 'auto',
    centeredSlides: false,
    loop: true,
    speed: 6000,
    grabCursor: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  }

  const overviewSlidesParams = {
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: false,
    loop: true,
    speed: 600,
    grabCursor: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".overview-pagination",
      clickable: true,
    },
  }


  const eventSlides = document.getElementById('eventSlides')
  if(eventSlides){
    new Swiper(eventSlides, eventSlidesParams)
  }

  const gallerySlides = document.getElementById('gallerySlides')
  if(gallerySlides){
    new Swiper(gallerySlides, servicePicSlideParams)
  }

  const overviewSlides = document.getElementById('overviewSlides')
  if(overviewSlides){
    new Swiper(overviewSlides, overviewSlidesParams)
  }

  setTimeout(()=>{
    scrollEvent()
  },0)

}

function scrollEvent(){
  
  if(window.innerWidth > 1119){
    const indexFlow = document.getElementById('indexFlow')
    const indexFlowTitle = document.getElementById('indexFlowTitle')
    if(indexFlow && indexFlowTitle){
      ScrollTrigger.create({
        trigger: indexFlow,
        pin: indexFlowTitle,
        //markers: true,
        start: "top 15%",
        end: "bottom bottom",
        invalidateOnRefresh: true,
      });
  
      const flowBoxItem = document.querySelectorAll('.flow__box__item')
      const stickflowPos = document.querySelectorAll('.stickflow__pos')
      if(flowBoxItem != null){
        let lastEl = flowBoxItem[flowBoxItem.length - 1]
        let finishPoint = 15 + ((flowBoxItem.length -1) * 3) + "%"
        for(let i = 0; i < flowBoxItem.length - 1; i++){
          let el = flowBoxItem[i]
          let endpoint = 15 + (i * 3) + "%"
          ScrollTrigger.create({
            trigger: el,
            endTrigger: lastEl,
            pin: el,
            //markers: true,
            start: `top ${endpoint}`,
            end: `top ${finishPoint}`,
            invalidateOnRefresh: true,
            pinSpacer: false,
            pinSpacing: false,
            id: 'indexPin',
            onEnter: ()=>{
              const indexPin = ScrollTrigger.getById('indexPin');
              indexPin.refresh()
              stickflowPos[i + 1].classList.add('active')
            },
            onLeaveBack: ()=>{
              const indexPin = ScrollTrigger.getById('indexPin');
              indexPin.refresh()
              stickflowPos[i + 1].classList.remove('active')
            }
          });
        }
  
        const indexFlowBar = document.getElementById('indexFlowBar')
        const indexFlowBarProgress = document.getElementById('indexFlowBarProgress')
        if(indexFlowBar){
          gsap.fromTo(indexFlowBarProgress,
            {
            },
            {
              height: '100%',
              scrollTrigger: {
                trigger: indexFlow,
                start: "top center",
                end: "bottom bottom+=50%",
                scrub: 1,
                once: false,
                //markers: true,
              }
            }
          )
        }
      }
    }
  }
}