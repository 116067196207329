export default function firstview(){

  class Particle {
    constructor(context) {
      this.x = 0;
      this.y = 0;
      this.xVelocity = 0;
      this.yVelocity = 0;
      this.currentopacity = 1;
      this.radius = 1;
      this.rotation = 0;
      this.context = context;
      this.image = new Image();
      this.image.src = "/images/common/smoke.png";
    }
  
    draw() {
      if (this.image) {
        var Offset = this.radius / -2;
        this.context.save();
        this.context.translate(this.x, this.y);
        this.context.globalAlpha = this.currentopacity;
        this.context.rotate(this.rotation);
        this.context.drawImage(this.image, Offset, Offset, this.radius, this.radius);
        this.context.restore();
        return;
      }
    }
  
    update(canvasWidth, canvasHeight, growth) {
      this.x += this.xVelocity;
      this.y += this.yVelocity;
      this.rotation += Math.PI / 36;
      this.currentopacity -= .009;
      this.radius += growth;
      
      if (this.currentopacity <= 0) {
        this.currentopacity = 1;
        this.x = canvasWidth / 2 - 180;
        this.y = canvasHeight;
        this.rotation = Math.random() * Math.PI * 2;
        this.radius = 1;
      }
    }
  
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    }
  
    setVelocity(x, y) {
      this.xVelocity = x;
      this.yVelocity = y;
    }
  }
  
  class ParticleSystem {
    constructor(canvasId, particleCount, targetFPS) {
      this.canvas = document.getElementById(canvasId);
      this.context = this.canvas.getContext('2d');
      this.canvasHeight = this.canvas.height;
      this.canvasWidth = this.canvas.width;
      this.particleCount = particleCount;
      this.targetFPS = targetFPS;
      this.particles = [];
      this.growth = 1.5;
    }
  
    init() {
      this.addParticle();
    }
  
    addParticle() {
      var particle = new Particle(this.context);
      particle.setPosition(0, this.canvasHeight);
      particle.setVelocity(Math.random() * 5 + 0.5, Math.random() * -.2);
      this.particles.push(particle);
      if (this.particles.length < this.particleCount) {
        setTimeout(() => {
          this.addParticle();
        }, Math.random() * 100 + 200);
      }
    }
  
    update() {
      this.particles.forEach((particle) => {
        particle.update(this.canvasWidth, this.canvasHeight, this.growth);
      });
    }
  
    draw() {
      this.context.clearRect(0, 0, this.canvasWidth, this.canvasHeight); // 背景をクリアして透明にする
      this.particles.forEach((particle) => {
        particle.draw();
      });
    }
  
    start() {
      setInterval(() => {
        this.update();
        this.draw();
      }, 2000 / this.targetFPS);
    }
  }
  
  if(document.getElementById('smokeEffect')){
    const particleSystem = new ParticleSystem('smokeEffect', 32, 50);
    particleSystem.init();
    particleSystem.start();
  }

}