import * as imagesLoaded from 'imagesloaded'
import GsapSmoothScroll from './class/GsapSmoothScroll'

import common from './common'
import index from './index'
import faq from './faq'
import firstview from './firstview'
import usevue from './usevue'


document.addEventListener('DOMContentLoaded', function() {
  imagesLoaded(document.getElementById('viewport'), ()=>{
    usevue()
    let smoother
    //smoother = new GsapSmoothScroll("#scrollArea", null, 1.6)
    common()
    index()
    faq()
    firstview()
    setProp()

    require("fslightbox");
    function setProp(){
      if(smoother != null){
        document.documentElement.style.setProperty('--screen', `${smoother.getOffsets()}px`)
      }else{
        cancelAnimationFrame(setProp)
      }
      requestAnimationFrame(setProp)
    }
  })
});


