import { createApp, ref } from 'vue'

export default function usevue(){
  const trigger = ref(false)
  const app = createApp({
    setup(){
      return {
        trigger,
      }
    }
  })
  app.mount('#site-header')
}
